import { Component, Input, OnInit } from '@angular/core';
import { MessageResponse } from 'aws-sdk/clients/pinpoint';
import { ChatBlazeService } from '../../service/chat-blaze/chat-blaze.service';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DatePipe } from '@angular/common';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { environment } from 'src/environments/environment';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {

  /** Focussed Message ID */
  @Input('message_id') message_id: string;

  /** Foccsed Channel ID */
  @Input('channel_id') channel_id: string;

  /** Focussed Conversation */
  @Input('conversation') conversation;

  /** Focussed Message */
  focussedMessage: MessageResponse | any;

  /** Focussed Message Data */
  messageData: any;
  updatedMessageData: any;


  /** Should we show accept/decline buttons? */
  showPriceChangeButtons: boolean = true;

  constructor(
    private chatService: ChatBlazeService,
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.getMessageContent();
  }

  getMessageContent() {
    this.chatService.getMessagesObs(this.channel_id, this.message_id).pipe(take(1)).subscribe((msgRes: any) => {
      if (msgRes) {
        // console.log('getMessageContent(): ', msgRes);
        this.doMessageStuff(msgRes);
      } else if (msgRes == false) {
        this.chatService.getMessageById(this.channel_id, this.message_id).pipe(take(1)).subscribe((msgRes: any) => {
          this.doMessageStuff(msgRes);
        });
      }
    })
  }

  doMessageStuff(msgRes) {
    this.focussedMessage = msgRes;
    if (msgRes.message == 'Rebooked Task' || msgRes.message == 'Rebooked Service') {
      // this.rebookedtask.emit(true);
    }
    this.focussedMessage.message = this.unicodeToChar(this.focussedMessage.message);
    let initialData = msgRes.data ? JSON.parse(msgRes.data) : undefined;
    if (msgRes.data) {
      switch (initialData.type) {
        case "price_change_request":
          this.getPriceChangeRequest(initialData);
          break;
        case "rebook_request":
          // this.rebooked.emit(true);
          // this.getRebookRequest(initialData);
          break;
        case "hire_student":
          this.messageData = initialData;
          // this.checkShouldHide("hire_student");
          // this.sendHiredOutput();
          break;
        case "student_mark_complete":
          this.messageData = initialData;
          // this.sendHiredOutput();
          break;
        case "pay_student":
          this.messageData = initialData;
          // this.checkShouldHide("pay_student")
          // this.sendHiredOutput();
          break;
        case "picture_message":
          this.messageData = initialData;
          break;
        case "document_message":
          this.messageData = initialData;
          break;
        case "audio_message":
          this.messageData = initialData;
          break;
      }
    }
  }

  getPriceChangeRequest(initialData) {
    this.chatService.getPriceChangeById(initialData.priceRequestId).subscribe(resRes => {
      initialData.newPriceObject = resRes
      this.messageData = initialData;
      if (!this.messageData.newPriceObject.isAccepted && !this.messageData.newPriceObject.isDeclined) {
        setTimeout(() => {
          this.getPriceChangeRequest(initialData);
        }, 500);
      }
    })
  }


  getMessageElementid() {
    return `blaze_chat_${this.message_id}`;
  }

  getNameColour() {
    let hexString = this.focussedMessage.userId ? this.focussedMessage.userId.toString(16) : this.focussedMessage.adminUserId.toString(16);
    let intColour = parseInt(hexString, 16);
    return `color: #${intColour}; font-weight: bold;`
  }

  getTextColour() {
    let hexString = this.focussedMessage.userId ? this.focussedMessage.userId.toString(16) : this.focussedMessage.adminUser.toString(16);
    let intColour = parseInt(hexString, 16);
    let textColour = this.invertColor(`#${intColour}`);
    return `color: ${textColour}; font-weight: bold !important;`;
  }

  invertColor(hex) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
  }

  padZero(str, len?) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }

  isMyMessage() {
    if (this.focussedMessage && (this.authService.uniTaskrUser.id === this.focussedMessage.userId ? this.focussedMessage.userId : this.focussedMessage.adminUserId)) {
      return true;
    } else {
      return false;
    }
  }

  getMessageType() {
    if (!this.messageData) {
      return 'default'
    } else {
      return this.messageData.type
    }
  }

  getSentTime() {
    if (this.focussedMessage && this.focussedMessage.createdDate) {
      if (this.isToday(this.focussedMessage.createdDate)) {
        return this.datePipe.transform(this.focussedMessage.createdDate, 'HH:mm');
      } else {
        return this.datePipe.transform(this.focussedMessage.createdDate, 'EE dd/MM HH:mm');
      }
    }
  }

  getSenderName(place?: string) {
    let foundOtherUser: boolean = false;
    let whichPart = 'firstname';
    if (this.focussedMessage.userId === this.authService.uniTaskrUser.userId) {
      if (place) {
        return "You"
      } else {
        return "You have ";
      }
    } else if (this.conversation) {
      for (let user of this.conversation.users) {
        if (user.userId == this.focussedMessage.userId ? this.focussedMessage.userId : this.focussedMessage.adminUserId) {
          if (whichPart == 'firstname') {
            return `${user.firstName}`;
          } else {
            return `${user.firstName} ${user.lastName}`;
          }
        }
      }
      if (!foundOtherUser) {
        return `${this.conversation.adminUsers[0].firstName} ${this.conversation.adminUsers[0].lastName}`;
      }
    }
  }

  getSenderImage() {
    if (this.focussedMessage.userId === this.authService.uniTaskrUser.userId) {
      return this.authService.uniTaskrUser.profilePictureUrl;
    } else if (this.conversation) {
      for (let user of this.conversation.users) {
        if (user.userId == this.focussedMessage.userId ? this.focussedMessage.userId : this.focussedMessage.adminUserId) {
          return user.profilePictureUrl;
        }
      }
    }
  }

  isToday(dateValue: string): boolean {
    let today = new Date();
    if (today.toISOString().split('T')[0] == new Date(dateValue).toISOString().split('T')[0]) {
      return true;
    } else {
      return false;
    }
  }

  openFullScreen() {
    console.log('openFullScreen()');
  }

  getImageSrc() {
    let availWidth = screen.availWidth;
    let imageWidth: number;
    imageWidth = 2 * 0.8 * availWidth;

    let cdnRequestObject = {
      bucket: "unitaskr-web-cdn",
      key: this.messageData.cdnKey, // "Screenshot 2021-11-17 at 11.10.24.png",
      edits: {
        resize: {
          width: imageWidth,
          fit: "cover"
        }
      }
    }

    let base64 = btoa(JSON.stringify(cdnRequestObject))
    return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
  }

  unicodeToChar(str) {
    const emojiRegex = /1f[0-9a-z]{3}/g;
    return str.replaceAll(emojiRegex, (match) => {
      return String.fromCodePoint(Number('0x' + match));
    })
  }

  async openDocument() {
    await Browser.open({ url: `${environment.uniTaskrApi.s3FileBaseUrl}${this.messageData.cdnKey}` });
  }

  getAudioSrc() {
    return `${environment.uniTaskrApi.cdnBaseUrl}${this.messageData.cdnKey}`;
  }


}
