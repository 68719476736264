import { Component, NgZone, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { Channel, DefaultGenerics } from 'stream-chat';
import { ChannelListToggleService, ChannelService, ChatClientService, DefaultStreamChatGenerics } from 'stream-chat-angular';
import { MessagingBoilerService } from '../../service/messaging-boiler.service';


@Component({
  selector: 'app-custom-channel-list',
  templateUrl: './custom-channel-list.component.html',
  styleUrls: ['./custom-channel-list.component.scss'],
})
export class CustomChannelListComponent implements OnInit {

  /** Query text to search inbox */
  searchQuery: string;

  /** Which channels are we going to show? */
  displayChannels: Channel<DefaultStreamChatGenerics>[] = [];

  constructor(
    private channelService: ChannelService,
    private chatClient: ChatClientService,
    private storageService: StorageService,
    public boilerService: MessagingBoilerService,
    private zone: NgZone
  ) { 
    
  }

  ngOnInit() {
    this.channelService.channels$.subscribe(
      async (c) => {
        console.log('Channel list Returned Channels: ', c);

        const unique = (value, index, self) => {
          return self.indexOf(value) === index
        }
        
        this.displayChannels = c.filter(unique)

        // this.displayChannels = c;
      }
    );
  }

  activateChannel(channel: Channel<DefaultStreamChatGenerics>) {
    this.channelService.setAsActiveChannel(channel);

  }

  getChattingWithAvatar(channel: Channel) {
    let memberObject = JSON.parse(JSON.stringify(channel.state.members));
    let membersArray = [];
    Object.keys(memberObject).forEach(member => {
      if (memberObject[member].user_id !== this.storageService.firebaseUser.uid && memberObject[member].user_id !== 'uniBot' && !memberObject[member].user_id.includes('unitaskr')) {
        membersArray.push(memberObject[member]);
      }
    });

    if (membersArray.length > 0 && membersArray[0].user.image) {
      return membersArray[0].user.image;
    } else {
      return "https://ionicframework.com/docs/img/demos/avatar.svg"
    }
  }

  getChattingWith(channel: Channel) {
    let memberObject = JSON.parse(JSON.stringify(channel.state.members));
    let membersArray = [];
    Object.keys(memberObject).forEach(member => {
      if (memberObject[member].user_id !== this.storageService.firebaseUser.uid && memberObject[member].user_id !== 'uniBot' && !memberObject[member].user_id.includes('unitaskr')) {
        membersArray.push(memberObject[member]);
      }
    });

    if (membersArray.length > 0) {
      return membersArray[0].user.name;
    } else {
      return "Just me.."
    }
  }

  getMessagePreview(messageSet) {
    return messageSet[messageSet.length -1] && messageSet[messageSet.length -1].text ? messageSet[messageSet.length -1].text : "Nothing yet...";
  }

  getUnreadCount(channel: Channel) {
    return this.zone.run(() => {
      return channel.state.unreadCount
    })
  }

  queryChannels() {
    console.log('searchChannels(): ', this.searchQuery)
    this.displayChannels = this.boilerService.allChannels.filter(channel => {
      if (channel.data.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || this.checkNameMatches(channel)) {
        return channel;
      }
    })
  }

  checkNameMatches(channel) {
    if (this.getChattingWith(channel).toLowerCase().includes(this.searchQuery)) {
      return channel;
    }
  }

}
