import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MessageComponent } from '../chat-blaze/components/message/message.component';
import { CreatorListItemComponent } from '../creator-profiles/components/creator-list-item/creator-list-item.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ImageLoadingComponent } from './image-loading/image-loading.component';
import { InboxListItemComponent } from './inbox-list-item/inbox-list-item.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

@NgModule({
    providers: [DatePipe],
    declarations: [
        LoadingSpinnerComponent,
        AvatarComponent,
        InboxListItemComponent,
        MessageComponent,
        ImageLoadingComponent,
        CreatorListItemComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        IonicModule,
        
    ],
    exports: [
        LoadingSpinnerComponent,
        AvatarComponent,
        InboxListItemComponent,
        MessageComponent,
        ImageLoadingComponent,
        CreatorListItemComponent,
    ]
})
export class ComponentsModule { }