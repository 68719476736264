import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-more-filters',
  templateUrl: './more-filters.component.html',
  styleUrls: ['./more-filters.component.scss'],
})
export class MoreFiltersComponent implements OnInit {

  moreStatusfilter = [
    { name: 'All', value: 0 },
    { name: 'Completed', value: 4 },
    { name: 'Paid', value: 5 },
    { name: 'Declined', value: 7 },
    { name: 'Relevant', value: 8 }
  ]
  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}

  selectFilter(filter) {
    this.popoverController.dismiss(filter);
  }

}
