import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpService,
  ) { }

  searchForUsers(queryText: string) {
    return this.http.runHttpCall(
      "POST",
      "api/users/admin/filter",
      "application/json", {
      searchQuery: queryText,
      page: {
        take: 15,
        skip: 0
      }
    })
  }

  findUniTaskrStudentById(userId: number) {
    return this.http.runHttpCall(
      "POST",
      `api/users/admin/creator/${userId}`,
      "application/json"
      )
  }

  searchUnitaskrForCreators(queryText: string) {
    return this.http.runHttpCall(
      "POST",
      "api/users/admin/filter",
      "application/json", {
      searchQuery: queryText,
      page: {
        take: 5,
        skip: 0
      }
    })
  }

  getContentCreators() {
    return this.http.runCreatorsHttpCall(
      "GET",
      "creators",
      "application/json"
      )
  }

  searchForContentCreators(queryText: string) {
    return this.http.runCreatorsHttpCall(
      "POST",
      "creators/filter",
      "application/json", {
      searchQuery: queryText,
    })
  }

  getCreatorProfile(creatorFirebaseUid: string) {
    return this.http.runCreatorsHttpCall(
      "GET",
      `creators/one-email/${creatorFirebaseUid}`,
      "application/json");
  }

  getUserProfile(userId) {
    return this.http.runHttpCall(
      "GET",
      `api/profile/user/${userId}`,
      "application/json")
  }

}

export interface userSearchResponse {
  userId: number,
  firstName: string,
  lastName: string,
  profilePicture: string,
  email: string,
  instaHandle: string,
  tikTokHandle: string,
}
