import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor() { }

  getImageSource(image, location: string) {
    // console.log('getImageSource(): ', image);
    if (image && typeof (image) == "string" && image.includes('http') || image && image.key && typeof (image.key) == "string" && image.key.includes('http')) {
      if (image.key) {
        return image.key
      } else {
        return image;
      }
    } else if (image == "string" || image == "") {
      return "../../../assets/images/placeholder.jpeg";
    } else {
      let availWidth = screen.availWidth;

      let imageWidth: number;
      let imageHeight: number;
      if (location === "focussed") {
        imageWidth = 2 * 0.95 * availWidth;
        imageHeight = 2 * 240;
      } else if (location === "gallery") {
        imageWidth = 106;
        imageHeight = 74;
      }

      let cdnRequestObject = {
        bucket: "unitaskr-web-cdn",
        key: image && image.key ? image.key : image && image.url ? image.url : image, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            width: imageWidth,
            height: imageHeight,
            fit: "cover"
          }
        }
      }

      let base64 = btoa(JSON.stringify(cdnRequestObject))
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }
}
