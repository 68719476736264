import { Component, NgZone, OnInit } from '@angular/core';
import { ImageService } from 'src/app/services/image/image.service';
import { TaskService } from '../../../services/task/task.service';
import { ProfileService } from '../../../services/profile/profile.service';
import { Router } from '@angular/router';
import { MessagingBoilerService } from '../../service/messaging-boiler.service';
import { ChannelService } from 'stream-chat-angular';
import { Channel } from 'stream-chat';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss'],
})
export class CustomHeaderComponent implements OnInit {

  /** Focussed Task offer for channel */
  focussedTaskOffer;
  /** Focussed Task for channel */
  focussedTask;
  /** Focussed Student for channel */
  focussedStudent;

  /** Active Channel */
  activeChannel: Channel | null;

  constructor(
    private router: Router,
    private boilerService: MessagingBoilerService,
    public imageService: ImageService,
    private taskService: TaskService,
    private profileService: ProfileService,
    private channelService: ChannelService,
    private zone: NgZone
  ) { }

  ngOnInit() {
    console.log('£££££££££ Init Header £££££££££');
    this.channelService.activeChannel$.subscribe(
      (c) => {
        console.log('HEADER active Channel: ', c);
        // this.zone.run(() => {
          if (c) {
            this.activeChannel = c;
            this.getHeaderUniTaskrFacts(c.id);
          }
        // })
      }
    );
  }

  getHeaderUniTaskrFacts(taskOfferId) {
    console.log('getHeaderUniTaskrFacts: ', taskOfferId);
    this.taskService.getTaskOfferById(taskOfferId).subscribe(taskOfferRes => {
      console.log('Task offer res: ', taskOfferRes);

      this.focussedTaskOffer = taskOfferRes;

      this.taskService.getTaskById(taskOfferRes.taskId).subscribe(taskRes => {
        console.log('taskRes: ', taskRes);
        this.focussedTask = taskRes;

        this.profileService.getUserbyId(taskOfferRes.userId).subscribe(profileRes => {
          console.log('Student Profile res: ', profileRes);
          this.focussedStudent = profileRes;
        }, err => {
          console.log('profileRes error: ', err);
        });
      }, err => {
        console.log('Error getting the task: ', err);
      })
    })
  }

  viewStudentProfile() {
    this.router.navigate([`/student-profile/${this.focussedTaskOffer.userId}`]);
  }

}
