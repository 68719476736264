import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StreamAutocompleteTextareaModule, StreamChatModule } from 'stream-chat-angular';
import { IonicModule } from '@ionic/angular';
import { CustomChannelListComponent } from './custom-channel-list/custom-channel-list.component';
import { CustomHeaderComponent } from './custom-header/custom-header.component';



@NgModule({
  declarations: [
    CustomChannelListComponent,
    CustomHeaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StreamChatModule,
    IonicModule,
    StreamAutocompleteTextareaModule,
  ],
  providers: [
    CurrencyPipe,
  ],
  exports: [
    CustomChannelListComponent,
    CustomHeaderComponent
  ]
})
export class BoilerChannelsModule { }
