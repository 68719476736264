import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { doc, getFirestore, setDoc, onSnapshot, arrayUnion } from '@firebase/firestore';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ShoutChannelService {

  focussedConversation;

  presence = {
    id: 0
  };

  constructor(
    private http: HttpService,
    private authService: AuthService
  ) { }

  getMyAdminInbox() {
    return this.http.runHttpCall(
      "GET",
      "api/channels/admin/admin-user",
      "application/json")
  }

  getChannelsForUser(userId) {
    return this.http.runHttpCall(
      "GET",
      `api/channels/admin/user/${userId}`,
      "application/json")
  }

  getFireChannel(chatId: string) {
    const db = getFirestore();
    let docRef = doc(db, "blaze-channels", `${chatId}`);
    const unsub = onSnapshot(docRef, (doc) => {
      this.presence[chatId] = doc.data();
    });
  }

  readFireMessageAdmin(channelId: string) {
    return this.http.runHttpCall(
      "PUT",
      `api/channels/admin/${channelId}/read-by`,
      "application/json", 
    )
  }

  createAdminChannel(channelRequest) {
    return this.http.runHttpCall(
      "POST",
      "api/channels/admin",
      "application/json",
      channelRequest)
  }

  getLinkedConversation(linkedEntityId: number, linkedEntityType: number) {
    return this.http.runHttpCall(
      "POST",
      "api/channels/linked-entity",
      "application/json",
      {
        linkedEntityId: linkedEntityId,
        linkedEntityType: linkedEntityType // TaskOffer = 1, Service = 2,
      }
    )
  }

  checkCanNext(userId: number) {
    return this.http.runHttpCall(
      "POST",
      `api/auth/check/user/${userId}/can-next`,
      "application/json"
    )
  }

  checkCanMigrate(userId: number) {
    return this.http.runHttpCall(
      "POST",
      `api/chatmigration/user/${userId}/can-migrate`,
      "application/json"
    )
  }

  chatMigrate() {
    return this.http.runHttpCall(
      "POST",
      `api/chatmigration/migrate`,
      "application/json"
    )
  }

  userChatMigrate(userId: number) {
    return this.http.runHttpCall(
      "POST",
      `api/chatmigration/user/${userId}/migrate`,
      "application/json"
    )
  }
}
