import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from "dompurify";
import { Router } from '@angular/router';
import { ShoutChannelService } from 'src/app/inbox/services/channel/channel.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PresenceService } from 'src/app/services/presence/presence.service';
import { LinkedEntityService } from 'src/app/chat-blaze/service/linked-entity/linked-entity.service';

@Component({
  selector: 'app-inbox-list-item',
  templateUrl: './inbox-list-item.component.html',
  styleUrls: ['./inbox-list-item.component.scss'],
})
export class InboxListItemComponent implements OnInit {

  /** Which conversation item are we showing? */
  @Input('conversation') conversation;

  /** Search query */
  @Input('searchQuery') searchQuery: string;

  /** Which user ID are we using to check presence? */
  focussedUserId: number;

  /** What is the user's name? */
  focussedName: string;

  /** Who sent the last message? */
  lastMessageSender: any;

  /** Focussed user presence */
  presence;


  constructor(
    private router: Router,
    private authService: AuthService,
    public presenceService: PresenceService,
    public channelService: ShoutChannelService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.getUserId(this.conversation);
    this.getName(this.conversation);
    
    setTimeout(() => {
      let senderAdmin
      if (this.channelService.presence[this.conversation.id] && this.channelService.presence[this.conversation.id].senderAdmin) {
        senderAdmin = this.channelService.presence[this.conversation.id].senderAdmin;
      } else if (this.channelService.presence[this.conversation.id] && this.channelService.presence[this.conversation.id].sender) {
        senderAdmin = this.channelService.presence[this.conversation.id].sender;
      } else {
        senderAdmin = "Action";
      }
      this.getLastSenderName(senderAdmin);
    }, 1250)

  }

  openChat(conversation) {
    this.channelService.focussedConversation = conversation;
    this.channelService.readFireMessageAdmin(this.conversation.id).subscribe(markedRead => {
      console.log('markedRead: ', markedRead);
    })
    this.router.navigate([`/chat-blaze/${conversation.id}`]);
  }

  getProfilePic(conversation) {
    for (let user of conversation.users) {
      if (user.userId !== this.authService.uniTaskrUser.userId) {
        return user.profilePictureUrl;
      }
    }
  }

  getUserId(conversation) {
    for (let user of conversation.users) {
      if (user.userId !== this.authService.uniTaskrUser.userId) {
        this.focussedUserId = user.userId;
        this.getPresence();
        break;
      }
    }
  }

  getName(conversation) {
    for (let user of conversation.users) {
      if (user.userId !== this.authService.uniTaskrUser.userId) {
        this.focussedName = `${user.firstName} ${user.lastName}`;
      }
    }
  }

  // getLastSenderName(isAdmin: boolean, id: number) {    
  getLastSenderName(id: number) {    
    this.lastMessageSender = this.conversation.adminUsers.filter(adU => {
      if (adU.adminUserId == id) {
        return adU.firstName;
      }
    });

    if (this.lastMessageSender.length == 0) {
      for (let user of this.conversation.users) {
        if (user.userId == id) {
          this.lastMessageSender = [user]    
        }
      }
    }
  }

  getMessageContent() {
    if (this.channelService.presence &&
      this.channelService.presence[this.conversation.id]
    ) {
      // console.log('this.channelService.presence: ', this.channelService.presence[this.conversation.id])
      return `<b>${this.lastMessageSender && this.lastMessageSender.length ? this.lastMessageSender[0].firstName : 'Loading...'} - </b> ${this.channelService.presence[this.conversation.id].lastMessage}`;
    }
    else {
      return this.getSanitized(this.conversation.name)
    }
  }

  getSanitized(htmlString: string) {
    let output = htmlString.replace("\n", "<br> <br>");
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }

  async getPresence() {
    if (this.focussedUserId) {
      this.presence = this.presenceService.getPresence(this.focussedUserId);
      this.channelService.getFireChannel(this.conversation.id);
    }
  }

  getHaveIread() {
    if (this.channelService.presence &&
      this.channelService.presence[this.conversation.id] !== this.authService.uniTaskrUser.userId) {
      return false;
    }
    return true;
  }


  shouldShow() {
    if (!this.searchQuery) {
      return true;
    } else if (this.focussedName && this.conversation) {
      if (this.focussedName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        this.conversation.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    }
  }
}
