import { Injectable } from '@angular/core';

import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  async init() {
    navigator.serviceWorker.ready.then((registration) => {
      if (!firebase.messaging.isSupported()) {
        return;
      }

      const messaging = firebase.messaging();

      // Register the Service Worker
      // messaging.useServiceWorker(registration);

      // Initialize your VAPI key
      // messaging
      //   .usePublicVapidKey(environment.firebaseConfig.vapidKey);
      messaging.getToken({
        serviceWorkerRegistration: registration,
        vapidKey: environment.firebaseConfig.vapidKey,
      }).then((token) => {
        console.log('the token: ', token);
      }).catch((err) => {
        console.log(err);
      })

      messaging.onMessage((payload) => {
        // If we want to display
        // a msg when the app is in foreground
        console.log(payload);
      });

      // Handle token refresh
      // messaging.onTokenRefresh(() => {
      //   messaging.getToken().then(
      //     (refreshedToken: string) => {
      //       console.log(refreshedToken);
      //     }).catch((err) => {
      //       console.error(err);
      //     });
      // });
    }, (err) => {
      console.error(err);
    });
  }

  async requestPermission() {
    if (!Notification) {
      return;
    }

    if (!firebase.messaging.isSupported()) {
      return;
    }

    try {
      const messaging = firebase.messaging();

      if (Notification.permission !== 'denied') {
        await Notification.requestPermission();
      }

      const token: string = await messaging.getToken();

      // User token
      console.log(token);
    } catch (err) {
      console.error(err);
    }
  }
}
