import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { Auth, createUserWithEmailAndPassword, getAuth, indexedDBLocalPersistence, initializeAuth, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';
import { Database, getDatabase } from 'firebase/database';
import { environment } from 'src/environments/environment';
import { ChannelService, ChatClientService } from 'stream-chat-angular';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../http/http.service';
import { PresenceService } from '../presence/presence.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private readonly database: Database;
  private readonly auth: Auth;

  /** Where should we navigate after login? */
  targetPath: string;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private authService: AuthService,
    private storageService: StorageService,
    private presenceService: PresenceService,
    private chatClientService: ChatClientService,
    public channelService: ChannelService,
  ) {
    this.targetPath = JSON.parse(JSON.stringify(window.location.pathname));
    console.log('£££££££££££££££££££££££');
    console.log('Init Firebase!');
    const firebaseApp = initializeApp(environment.firebaseConfig);
    console.log('£££££££££££££££££££££££');

    if (Capacitor.isNativePlatform()) {
      initializeAuth(firebaseApp, {
        persistence: indexedDBLocalPersistence
      });
    }

    this.database = getDatabase(firebaseApp);
    this.auth = getAuth(firebaseApp);
  }

  startListeningToAuthStateChange() {
    const auth = getAuth();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.httpService.token = await user.getIdToken();

        console.log('^^^^^^')
        console.log('Auth State Changed: ', user);
        console.log('^^^^^^')

        if (user && user.emailVerified) {
          this.authService.adminAuth(user.email).subscribe(adminAuthRes => {
            console.log('adminAuthRes: ', adminAuthRes);
            this.authService.uniTaskrUser = adminAuthRes;
            this.storageService.firebaseUser = user;
            this.presenceService.setPresence("online");

            console.log('ONE ONE ONE ONE ONE: ', this.targetPath);

            if (this.targetPath == "/login" || this.targetPath == "/" || this.targetPath == "/thinking" || this.targetPath == "/landing" || this.targetPath == "/messaging-boiler/0") {
              console.log('ONE ONE ONE ONE ONE navigate to Boiler...');
              // this.router.navigate([`/messaging-boiler/0`]);
              this.router.navigate([`/shout`]);
            } 
            // else if (this.targetPath == "/messaging-boiler/0") {
            //   this.router.navigate([`/`]);
            // } 
            else {
              this.router.navigate([`${this.targetPath}`]);
            }
          }, error => {
            console.log("Admin AuthLogin Error", error);
            // if(error.)
          });
        } else if (user && !user.emailVerified) {
          this.sendVerificationEmail();
          this.router.navigate(['/verify-email']);
        }
        else {
          this.router.navigate([`/landing`]);
        }
      } else {
        this.router.navigate([`/landing`]);
      }

    })
  }

  loginUserWithEmail(email: string, password: string) {
    console.log('loginUserWithEmail()')
    console.log('email: ', email);
    console.log('password: ', password);
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  createUserWithEmail(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  sendVerificationEmail() {
    let user = this.auth.currentUser;
    return sendEmailVerification(user);
  }

  async checkUserExists(email: string, password?: string) {
    // const auth = getAuth();
    return signInWithEmailAndPassword(this.auth, email, password ? password : '');
  }

}
