import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ChatBlazeService } from 'src/app/chat-blaze/service/chat-blaze/chat-blaze.service';
import { BlazeMessagingService } from 'src/app/messaging-boiler/service/blaze-messaging/blaze-messaging.service';
import { Channel } from 'stream-chat';
import { ChannelService } from 'stream-chat-angular';

@Component({
  selector: 'app-custom-footer',
  templateUrl: './custom-footer.component.html',
  styleUrls: ['./custom-footer.component.scss'],
})
export class CustomFooterComponent implements OnInit {

  @Input() channel!: Channel;
  activeChannel: Channel | undefined;

  activeChannelSub: Subscription;

  /** Focussed Task offer for channel */
  focussedTaskOffer;
  /** Focussed Student for channel */
  focussedStudent;
  /** Focussed Task for channel */
  focussedTask;

  amHirer: boolean = false;

  paymentSummary;

  myCurrencyCode;
  myConversionRate;

  newMessage: string;

  /** Updated profile picture files */
  selectedFiles: FileList;

  /** What device are we on? */
  isMobile: boolean = true;
  
  constructor(
    private platform: Platform,
    private channelService: ChannelService,
    public chatService: ChatBlazeService,
    public messagingService: BlazeMessagingService,
  ) { 
    this.activeChannelSub = this.channelService.activeChannel$.subscribe(chanRes => {
      this.activeChannel = chanRes;
    });
  }

  async ngOnInit() {
    if (this.platform.is('mobile')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  async sendMessage() {
    console.log('sendMessage()');
    const message = await this.channelService.sendMessage(
      this.newMessage
    );
    this.newMessage = undefined;
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.sendAttachment();
  }

  async sendAttachment() {
    console.log('sendAttachment()');

    // const response = await this.activeChannel.sendFile(this.selectedFiles[0]);
    const response = await this.messagingService.activeChannel.sendFile(this.selectedFiles[0]);
    console.log('Attachment Sent: ', response);
    if (response.file.includes('mp4') || response.file.includes('mov')) {
      const message = await this.channelService.sendMessage(
        '📹 video',
        [{
          file: response.file,
          type: 'video',
          thumb_url: response.file
        }]
      );
    } else {
      const message = await this.channelService.sendMessage(
        '📸 image',
        [{
          file: response.file,
          type: 'image',
          thumb_url: response.file
        }]
      );
    }
  }

  async attachFile() {
    console.log('attachFile()');
  }

  async getSize() {
    return 8;
  }

  ngOnDestroy() {
    this.activeChannelSub.unsubscribe()
  }

}
