import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { StreamChat } from 'stream-chat';
import { BlazeMessagingService } from './messaging-boiler/service/blaze-messaging/blaze-messaging.service';
import { AuthService } from './services/auth/auth.service';
import { FirebaseService } from './services/firebase/firebase.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { StorageService } from './services/storage/storage.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    // { title: 'Inbox', url: '/inbox-stream-ng/0', icon: 'mail' },
    // { title: 'Messaging', url: '/messaging/0', icon: 'chatbubble' },
    // { title: 'Inbox', url: '/messaging-boiler/0', icon: 'chatbubble' },
    { title: 'SHOUT', url: '/shout', icon: 'megaphone' },
    { title: 'Creator Profiles', url: '/creator-profiles', icon: 'ice-cream' }
  ];

  public adminAppPages = [
    { title: 'Search', url: '/user-inbox-search', icon: 'search' },
    { title: 'Cloud Messaging', url: '/send-notifications', icon: 'send' },
  ];
  

  constructor(
    private router: Router,
    private firebaseService: FirebaseService,
    public authService: AuthService,
    private notificationsService: NotificationsService,
    private platform: Platform,
    private storageService: StorageService,
    private blazeService: BlazeMessagingService
  ) {
    this.firebaseService.startListeningToAuthStateChange();
  }

  async ngOnInit() {
    // await this.notificationsService.init();
  }

  async ngAfterViewInit() {

    
  }

  getUnreadCount() {
    setTimeout(async () => {
      const chatClient = StreamChat.getInstance<any>(environment.stream_io.apiKey);

      const user = await chatClient.connectUser({ id: `unitaskr_${this.storageService.firebaseUser.uid}` }, environment.stream_io.apiKey);
      console.log('$$$$$ user: ', user);
      // this.getUnreadCount()
    }, 5000)
  }

  openAccount() {
    this.router.navigate(['/account'])
  }

  shouldHideMenuOption(page) {
    if (page.url == '/creator-profiles') {
      if (this.authService.uniTaskrUser && this.authService.uniTaskrUser.permissionGroupId !== 7) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getNewMessages() {
    return this.blazeService.unreadMessageTotal;
  }
}
