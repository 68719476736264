import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardService } from './services/authguard/authguard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'thinking',
    pathMatch: 'full'
  },
  {
    path: 'thinking',
    loadChildren: () => import('./thinking/thinking.module').then( m => m.ThinkingPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('./inbox/inbox.module').then( m => m.InboxPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: 'user-inbox-search',
    loadChildren: () => import('./user-inbox-search/user-inbox-search.module').then( m => m.UserInboxSearchPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'user-inbox/:userId',
    loadChildren: () => import('./user-inbox/user-inbox.module').then( m => m.UserInboxPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'chat-blaze/:channel_id',
    loadChildren: () => import('./chat-blaze/chat-blaze.module').then( m => m.ChatBlazePageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'chat-profile/:conversation_id',
    loadChildren: () => import('./chat-blaze/pages/chat-profile/chat-profile.module').then( m => m.ChatProfilePageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'student-profile/:userId',
    loadChildren: () => import('./chat-blaze/pages/student-profile/student-profile.module').then( m => m.StudentProfilePageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'shout',
    loadChildren: () => import('./shout/shout.module').then( m => m.ShoutPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'shout-campaign/:id',
    loadChildren: () => import('./shout-campaign/shout-campaign.module').then( m => m.ShoutCampaignPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'send-notifications',
    loadChildren: () => import('./send-notifications/send-notifications.module').then( m => m.SendNotificationsPageModule)
  },
  {
    path: 'inbox-stream-ng/:id',
    loadChildren: () => import('./inbox-stream-ng/inbox-stream-ng.module').then( m => m.InboxStreamNgPageModule)
  },
  {
    path: 'creator-profiles',
    loadChildren: () => import('./creator-profiles/creator-profiles.module').then( m => m.CreatorProfilesPageModule)
  },
  {
    path: 'creator-profile-add',
    loadChildren: () => import('./creator-profile-add/creator-profile-add.module').then( m => m.CreatorProfileAddPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'messaging/:id',
    loadChildren: () => import('./messaging/messaging.module').then( m => m.MessagingPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'messaging-boiler/:id',
    loadChildren: () => import('./messaging-boiler/messaging-boiler.module').then( m => m.MessagingBoilerPageModule),
    canActivate: [AuthguardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
