import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /** Unitaskr Auth Bits */
  public uniTaskrUser: any;
  public myProfile: any;
  public permissionList: any;

  constructor(
    private http: HttpService
  ) { }

  /** Admin Funk Login Flow method */

  legacyLogin(email: string, password: string) {
    return this.http.runHttpCall(
      "POST",
      `api/adminauth/legacy/login`,
      "application/json", {
      email,
      password
    })
  }

  linkLogins(email: string, password: string) {
    return this.http.runHttpCall(
      "POST",
      `api/adminauth/legacy/link-login`,
      "application/json", {
      email,
      password
    })
  }

  // adminAuth(email: string, password: string) {
  adminAuth(email: string) {
    return this.http.runHttpCall(
      "POST",
      `api/adminauth/login`,
      "application/json", 
      // {
      // email,
      // password
      // }
    )
  }

  /** Admin Funk Login Flow method END */

  /** UniTaskr Auth Funk */
  login(email: string) {
    return this.http.runHttpCall(
      "POST",
      "api/auth/login",
      "application/json",
      {
        email: email
      })
  }

  getMyProfile() {
    return this.http.runHttpCall(
      "GET",
      "api/profile",
      "application/json")
  }

  getMyAccountInfo() {
    return this.http.runHttpCall(
      "GET",
      "api/account",
      "application/json")
  }
  /** UniTaskr Auth Funk END */
}
