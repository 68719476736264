import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpService
  ) { }

  getUserbyId(userdId: number) {
    return this.http.runHttpCall(
      "GET",
      `api/profile/user/${userdId}`,
      "application/json"
    )
  }
}
