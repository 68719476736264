import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/user-inbox-search/services/user/user.service';

@Component({
  selector: 'app-creator-list-item',
  templateUrl: './creator-list-item.component.html',
  styleUrls: ['./creator-list-item.component.scss'],
})
export class CreatorListItemComponent implements OnInit {

  /** Unitaskr Search Result */
  @Input('unitaskr') unitaskr;

  /** UGC Creator Profile */
  creatorProfile;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getUGCProfile();
  }

  getUGCProfile() {
    // this.userService.getCreatorProfile(this.unitaskr.blazeFirebaseUid).subscribe(creatorRes => {
    this.userService.getCreatorProfile(this.unitaskr.email).subscribe(creatorRes => {
      console.log('creatorRes: ', creatorRes);
      this.creatorProfile = creatorRes;
    }, err => {
      console.log('creatorRes error: ', err);
    });
  }

}
