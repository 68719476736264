import { Component, NgZone, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { Channel, DefaultGenerics } from 'stream-chat';
import { ChannelListToggleService, ChannelService, ChatClientService, DefaultStreamChatGenerics } from 'stream-chat-angular';
import { MessagingService } from '../../service/messaging.service';


@Component({
  selector: 'app-custom-channel-list',
  templateUrl: './custom-channel-list.component.html',
  styleUrls: ['./custom-channel-list.component.scss'],
})
export class CustomChannelListComponent implements OnInit {

  

  constructor(
    private channelService: ChannelService,
    public messagingService: MessagingService,
    private storageService: StorageService,
    private zone: NgZone
  ) { 
    // this.messagingService.intialiseChannels(0);
  }

  ngOnInit() {}

  activateChannel(channel: Channel<DefaultStreamChatGenerics>) {
    this.messagingService.menuIsOpen = false;
    this.channelService.setAsActiveChannel(channel);
    channel.on(event => {
      
      if (event.type == 'message.new' && event.cid == this.messagingService.activeChannel.cid) {
        console.log('*************')
        console.log('New message on channel: ', event);
        if (event.message.user.id == 'uniBot') {
          console.log('Message from bot....');
          this.messagingService.shouldGetTaskAgain.next(true);
        }
        console.log('*************')
      }
    })
  }

  getChattingWithAvatar(channel: Channel) {
    let memberObject = JSON.parse(JSON.stringify(channel.state.members));
    let membersArray = [];
    Object.keys(memberObject).forEach(member => {
      if (memberObject[member].user_id !== this.storageService.firebaseUser.uid && memberObject[member].user_id !== 'uniBot') {
        membersArray.push(memberObject[member]);
      }
    });

    if (membersArray.length > 0 && membersArray[0].user.image) {
      return membersArray[0].user.image;
    } else {
      return "https://ionicframework.com/docs/img/demos/avatar.svg"
    }
  }

  getChattingWith(channel: Channel) {
    let memberObject = JSON.parse(JSON.stringify(channel.state.members));
    let membersArray = [];
    Object.keys(memberObject).forEach(member => {
      if (memberObject[member].user_id !== this.storageService.firebaseUser.uid && memberObject[member].user_id !== 'uniBot') {
        membersArray.push(memberObject[member]);
      }
    });

    if (membersArray.length > 0) {
      return membersArray[0].user.name;
    } else {
      return "Just me.."
    }
  }

  getMessagePreview(messageSet) {
    return messageSet[messageSet.length -1] && messageSet[messageSet.length -1].text ? messageSet[messageSet.length -1].text : "Nothing yet...";
  }

  getUnreadCount(channel: Channel) {
    return this.zone.run(() => {
      return channel.state.unreadCount
    })
  }

  
}
