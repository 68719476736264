import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(
    private http: HttpService
  ) { }

  getFilterTasksv2(filterRequest): Observable<TaskResponse[]> {
    return this.http.runHttpCall(
      "POST",
      "api/tasks/filter/v2",
      "application/json",
      filterRequest
    )
  }

  getTaskByIdPublic(id: string) {
    return this.http.runHttpCall(
      "GET",
      `api/tasks/${id}`,
      "application/json")
  }

  getTaskById(id: string) {
    return this.http.runHttpCall(
      "GET",
      `api/tasks/admin/${id}`,
      "application/json")
  }

  getTaskOfferById(offerId: number) {
    return this.http.runHttpCall(
      "GET",
      `api/taskoffers/detailed/${offerId}`,
      "application/json")
  }
}

export interface TaskResponse {
  budget: number;
  budgetType: number;
  dueDate: string;
  duration: number;
  hiredCount: number;
  isCompleted: false
  isHired: false
  isPinTask: true
  isSurvey: false
  location: string;
  shortAddress: string;
  locationType: number;
  myOffersCount: number;
  offersCount: number;
  profilePictureUrl: string;
  questionsCount: number;
  shoutStatus: number;
  startedCount: number;
  taskId: number;
  taskerCount: number;
  title: string;
  userId: string;
  lat: number;
  lon: number;
}
