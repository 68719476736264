import { Injectable, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';
import { Channel, StreamChat } from 'stream-chat';
import { ChannelActionsContext, ChannelPreviewContext, ChannelService, ChatClientService, DefaultStreamChatGenerics, StreamI18nService } from 'stream-chat-angular';

@Injectable({
  providedIn: 'root'
})
export class BlazeMessagingService {

  /** has the chat been initialised?? */
  isInit: boolean = false;

  /** have the channels been initialised? */
  isChannelInit: boolean = false;

  /** Should this be visible?? */
  showMessaging: boolean = false;
  chatIsReady$!: Observable<boolean>;

  @ViewChild('channelActionsTemplate')
  private channelActionsTemplate!: TemplateRef<ChannelActionsContext>;
  @ViewChild('channelPreview')
  private channelPreview!: TemplateRef<ChannelPreviewContext>;


  /** Is the side menu (list of channels) visible? */
  menuIsOpen: boolean = true;

  /** Stream Subscriptions */
  channelSubscription$: Subscription;

  /** All Channels */
  allChannels: Channel<DefaultStreamChatGenerics>[] = [];

  /** Active Channel */
  activeChannel: Channel | null;

  /** Behaviour subject so focussed chat knows to refresh the task offer */
  shouldGetTaskAgain: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** time to check for notifications */
  refreshSearchTimeout;

  /** All the channels with me in them */
  allMyChannels: Channel[];

  /** Total unread messages */
  public unreadMessageTotal: number = 0;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private authService: AuthService,
    private streamI18nService: StreamI18nService,
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private zone: NgZone
  ) { }

  async initialiseChat(initChat) {
    console.log('initialiseChat: ', initChat)

    if (this.storageService.firebaseUser && this.authService.uniTaskrUser && this.authService.uniTaskrUser.streamToken) {
      if (!this.isInit) {
        this.isInit = true;
        this.streamI18nService.setTranslation();

        console.log("&&&&&&&&&&&")
        console.log('Start Blaze Messaging: ', this.chatIsReady$);
        console.log("&&&&&&&&&&&")

        this.chatIsReady$ = this.getStreamToken().pipe(
          switchMap((streamToken) => {
            console.log('Stream stage 1');
            return this.chatService.init(
              environment.stream_io.apiKey,
              `unitaskr_${this.storageService.firebaseUser.uid}`,
              streamToken
            )
          }),
          map((res: any) => {
            console.log('Stream stage 2: ', res);
            this.unreadMessageTotal = res.me.total_unread_count;
            this.intialiseChannels();
            this.listenToNewMessages();
            return true
          }),
          catchError(() => {
            console.log('catchError');
            return of(false)
          })
        )
        if (!this.activeChannel) {
          this.menuIsOpen = true;
        } else {
          this.menuIsOpen = false;
        }


      }
    } else {
      this.router.navigate(['/thinking'])
    }
  }

  getStreamToken() {
    return of(this.authService.uniTaskrUser.streamToken)
  }

  listenToNewMessages() {
    console.log('listenToNewMessages()');
    const chatClient = StreamChat.getInstance<any>(environment.stream_io.apiKey);
    chatClient.on((event) => {
      if (event.type == 'message.new') {
        console.log('$$$$$$$ listenToNewMessages new message: ', event)
        this.unreadMessageTotal += 1;
      }
      if (event.type == 'message.read' && this.unreadMessageTotal > -1) {
        console.log('$$$$$$$ listenToNewMessages read message: ', event)
        this.unreadMessageTotal -= 1;
      }
    });
  }

  intialiseChannels() {
    this.zone.run(() => {
      console.log('intialiseChannels(): ', `unitaskr_${this.storageService.firebaseUser.uid}`);
      if (!this.isChannelInit) {
        this.isChannelInit = true;

        this.channelService.init({
          type: 'messaging',
          // members: { $in: [`unitaskr_${this.storageService.firebaseUser.uid}`] },
          members: { $in: ['unitaskr_UH7d04PGu6M3axQyuSrgMIRjylH2'] },
        }, {}, {}, false).then(initRes => {
          console.log('initRes: ', initRes);
          this.channelService.channels$.subscribe(
            async (c) => {
              this.zone.run(() => {
                console.log('****** Returned Channels: ', c);

                const unique = (value, index, self) => {
                  return self.indexOf(value) === index
                }

                this.allChannels = c.filter(unique);

                // this.listenToMyChannels();
              });
            }
          );

          this.channelService.activeChannel$.subscribe(
            (c) => {
              console.log('Active Channel Updated!!: ', c);
              this.zone.run(() => {
                this.activeChannel = c;
                console.log('New Active Channel!!: ', this.activeChannel);
              })
            }
          );

        })
      }
    })
  }

  async listenToMyChannels() {
    const chatClient = StreamChat.getInstance<any>(environment.stream_io.apiKey);
    // const filter = { type: 'messaging', members: { $in: [`unitaskr_${this.storageService.firebaseUser.uid}`] } };
    const filter = { type: 'messaging', members: { $in: ['unitaskr_UH7d04PGu6M3axQyuSrgMIRjylH2'] } };
    const channels = await chatClient.queryChannels(filter);
    this.allMyChannels = channels;
    console.log('listenToMyChannels() channels: ', channels);
    this.refreshSearchTimeout = setTimeout(() => {
      this.listenToMyChannels();
    }, 7500);
  }
}
