import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StorageService } from '../../../services/storage/storage.service';
import { DatePipe } from '@angular/common';
import { ModalController, Platform } from '@ionic/angular';
import { BlazeMessagingService } from 'src/app/messaging-boiler/service/blaze-messaging/blaze-messaging.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FullSizeImageViewerPage } from 'src/app/full-size-image-viewer/full-size-image-viewer.page';

@Component({
  selector: 'app-custom-message-list',
  templateUrl: './custom-message-list.component.html',
  styleUrls: ['./custom-message-list.component.scss'],
})
export class CustomMessageListComponent implements OnInit {

  @ViewChild('scrollComplete', { static: true }) scrollComplete: any;

  /** What device are we on? */
  isMobile: boolean = true;

  /** Today's Date */
  today = new Date();

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    public messagingService: BlazeMessagingService,
    private storageService: StorageService,
    private datePipe: DatePipe,
    private authService: AuthService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      let element = document.getElementById(this.messagingService.activeChannel.state.messageSets[0].messages[this.messagingService.activeChannel.state.messageSets[0].messages.length - 1].id);
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 700);

    if (this.platform.is('mobile')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.messagingService.activeChannel.on(event => {
      if (event.type == 'message.new' && event.cid == this.messagingService.activeChannel.cid) {
        setTimeout(() => {
          console.log('##########')
          console.log('New message on channel: ', event);
          let element = document.getElementById(event.message.id);
          element.scrollIntoView({ behavior: 'smooth', block: 'end' });
          console.log('##########')
        }, 175)
      }
    })
  }


  setMineOrYours(message) {
    if (message.user.id == `unitaskr_${this.storageService.firebaseUser.uid}`) {
      return 'my_message'
    } else {
      return 'your_message'
    }
  }

  getSentTime(updated_at) {
    if (this.isToday(updated_at)) {
      return this.datePipe.transform(updated_at, 'HH:mm');
    } else {
      return this.datePipe.transform(updated_at, 'EE dd/MM HH:mm');
    }
  }

  isToday(dateValue: string): boolean {
    this.today = new Date();
    if (this.today.toISOString().split('T')[0] == new Date(dateValue).toISOString().split('T')[0]) {
      return true;
    } else {
      return false;
    }
  }

  getShortDateDisplay(focusDate) {
    let date: Date = new Date('Feb 5, 2021');
    let dateVal: number = date.getTime();
    let datePipe: DatePipe = new DatePipe('en-GB');
    return datePipe.transform(dateVal, 'shortDate');
  }

  getReadMsg(message) {
    if (message.readBy && message.readBy.length > 0) {
      return 'primary'
    } else {

    }
  }

  getNameColour(user) {
    let hexString = user.id ? user.id.toString(16) : '000';
    let intColour = parseInt(hexString, 16);
    return `color: #${intColour}; font-weight: bold;`
  }

  getBgColour() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    if (prefersDark.matches) {
      return "width: 100%; --background: #000;"
    } else {
      return "width: 100%; --background: #fff;"
    }
  }

  getLabelMargin() {
    if (this.isMobile) {
      return 'margin-left: 40px;'
    } else {
      return 'margin-left: 48px;'
    }
  }

  async openFullScreen(message) {
    console.log('openFullScreen(): ', message);
    // const modal = await this.modalController.create({
    //   component: FullSizeImageViewerPage,
    //   swipeToClose: true,
    //   componentProps: {
    //     images: [{ url: message.attachments[0].file }],
    //     focussed: message.attachments[0]
    //   }
    // });
    // await modal.present();
  }
}
