import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from 'src/app/components/components.module';
import { ApplicantItemComponent } from './applicant-item/applicant-item.component';
import { CustomFooterComponent } from './custom-footer/custom-footer.component';
import { CustomMessageListComponent } from './custom-message-list/custom-message-list.component';
import { MoreFiltersComponent } from './more-filters/more-filters.component';


@NgModule({
    providers: [DatePipe],
    declarations: [
        MoreFiltersComponent,
        ApplicantItemComponent,
        CustomMessageListComponent,
        CustomFooterComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        IonicModule,
        ComponentsModule
    ],
    exports: [
        MoreFiltersComponent,
        ApplicantItemComponent,
        CustomMessageListComponent,
        CustomFooterComponent
    ]
})
export class ShoutCampaignComponentsModule { }