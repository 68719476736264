import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';
import { Channel, StreamChat } from 'stream-chat';
import { ChannelService, ChatClientService, DefaultStreamChatGenerics, StreamI18nService } from 'stream-chat-angular';

@Injectable({
  providedIn: 'root'
})
export class MessagingBoilerService {

  /** has the chat been initialised?? */
  isInit: boolean = false;

  chatIsReady$!: Observable<boolean>;

  /** Stream Subscriptions */
  channelSubscription$: Subscription;
  /** All Channels */
  allChannels: Channel<DefaultStreamChatGenerics>[] = [];

  /** Active Channel */
  activeChannel: Channel | null;

  constructor(
    private router: Router,
    private streamI18nService: StreamI18nService,
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private authService: AuthService,
    private storageService: StorageService,
    private zone: NgZone
  ) { }

  initBoiler(initChat) {
    console.log('&&&&&&&&')
    console.log('initBoiler()')
    console.log('this.storageService.firebaseUser: ', this.storageService.firebaseUser);
    console.log('this.authService.uniTaskrUser: ', this.authService.uniTaskrUser);
    console.log('&&&&&&&&')
    if (this.storageService.firebaseUser && this.authService.uniTaskrUser && this.authService.uniTaskrUser.streamToken) {
      if (!this.isInit) {
        this.isInit = true;
        this.streamI18nService.setTranslation();

        console.log('Start spinning up stream...')
        console.log('this.storageService.firebaseUser: ', this.storageService.firebaseUser)

        this.chatIsReady$ = this.getStreamToken().pipe(
          switchMap((streamToken) => this.chatService.init(
            environment.stream_io.apiKey,
            `unitaskr_${this.storageService.firebaseUser.uid}`,
            streamToken
          )),
          switchMap(() => this.channelService.init({
            type: 'messaging',
            members: { $in: [`unitaskr_${this.storageService.firebaseUser.uid}`] },
          }, {}, {}, false)),
          map(() => {

            console.log('Stream stage 2');

            this.channelSubscription$ = this.channelService.channels$.subscribe(
              async (c) => {
                console.log('Returned Channels: ', c);

                const unique = (value, index, self) => {
                  return self.indexOf(value) === index
                }
                
                this.allChannels = c.filter(unique)

                console.log('777777')
                console.log('initChat: ', initChat)
                console.log('777777')

                if (initChat !== 0 && initChat !== '0') {
                  const chatClient = StreamChat.getInstance<any>(environment.stream_io.apiKey);
                  const filter = { type: 'messaging', id: initChat };
                  const channels = await chatClient.queryChannels(filter);

                  console.log('Set Param Chat!!: ', channels);
                  console.log('Set Param Chat!!')
                  console.log('Set Param Chat!!')

                  this.channelService.setAsActiveChannel(channels[0]);
                } else {
                  console.log('777777')
                  console.log('ELSE')
                  console.log('777777')
                }


              }
            );
            this.channelService.activeChannel$.subscribe(
              (c) => {
                console.log('$$$$$$ bolier service activeChannel $$$$$$: ', c);
                this.zone.run(() => {
                  this.activeChannel = c;
                })
              }
            );
            return true
          }),
          catchError((err) => {
            console.log('Caught error: ', err);
            return of(false)
          })
        )
      } else {
        console.log('CHAT ALREADY INIT!!!');
      }
    } else {
      this.router.navigate(['/thinking'])
    }
  }


  initBoilerShout() {
    console.log('&&&&&&&&')
    console.log('initBoilerShout()')
    console.log('this.storageService.firebaseUser: ', this.storageService.firebaseUser);
    console.log('this.authService.uniTaskrUser: ', this.authService.uniTaskrUser);
    console.log('&&&&&&&&')
    if (this.storageService.firebaseUser && this.authService.uniTaskrUser && this.authService.uniTaskrUser.streamToken) {
      if (!this.isInit) {
        // this.isInit = true;
        this.streamI18nService.setTranslation();

        console.log('Start spinning up stream...')
        console.log('this.storageService.firebaseUser: ', this.storageService.firebaseUser)

        this.chatIsReady$ = this.getStreamToken().pipe(
          switchMap((streamToken) => this.chatService.init(
            environment.stream_io.apiKey,
            `unitaskr_${this.storageService.firebaseUser.uid}`,
            streamToken
          )),
          switchMap(() => this.channelService.init({
            type: 'messaging',
            members: { $in: [`unitaskr_${this.storageService.firebaseUser.uid}`] },
          }, {}, {}, false)),
          map(() => {
            console.log('Stream stage 2');

            this.intialiseChannels()
            return true
          }),
          catchError((err) => {
            console.log('Caught error: ', err);
            return of(false)
          })
        )
      } else {
        console.log('CHAT ALREADY INIT!!!');
      }
    } else {
      this.router.navigate(['/thinking'])
    }
  }

  intialiseChannels() {
    if (!this.isInit) {
      this.isInit = true;

      this.channelService.init({
        type: 'messaging',
        members: { $in: [this.storageService.firebaseUser.uid] },
      }, {}, {}, false).then(initRes => {
        console.log('initRes: ', initRes);
        this.channelService.channels$.subscribe(
          async (c) => {
            console.log('Returned Channels: ', c);

            const unique = (value, index, self) => {
              return self.indexOf(value) === index
            }
            
            this.allChannels = c.filter(unique)

          }
        );

        this.channelService.activeChannel$.subscribe(
          (c) => {
            console.log('Active Channel Updated!!: ', c);
            this.zone.run(() => {
              this.activeChannel = c;
              console.log('New Active Channel!!: ', this.activeChannel);
            })
          }
        );
      })
    }
  }

  startAllChannelSub() {
    console.log('startAllChannelSub()');
    this.channelService.channels$.subscribe(
      async (c) => {
        console.log('startAllChannelSub() Returned Channels: ', c);

        if (c) {
          const unique = (value, index, self) => {
            return self.indexOf(value) === index
          }
          
          this.allChannels = c.filter(unique);
        }
      }
    );
  }

  activateChannel(channel: Channel<DefaultStreamChatGenerics>) {
    this.channelService.setAsActiveChannel(channel);
  }

  onCreate(name: string) {
    const dasherizedName = name.replace(/\s+/g, '-').toLowerCase();
    const channel = this.chatService.chatClient.channel(
      'messaging',
      dasherizedName,
      {
        name,
        members: [`unitaskr_${this.storageService.firebaseUser.uid}`]
      });
    from(channel.create());
  }

  getStreamToken() {
    return of(this.authService.uniTaskrUser.streamToken)
  }

}
