import { Injectable } from '@angular/core';
import { doc, getFirestore, setDoc, onSnapshot } from '@firebase/firestore';
import { merge } from 'rxjs';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class PresenceService {

  /** Current presence status of list item member */
  presence = {
    id: 0
  };

  constructor(
    private storageService: StorageService
  ) {
    this.updateOnAway();
  }

  getUser() {
    return this.storageService.firebaseUser;
  }

  get timestamp() {
    return Date();
  }

  async setPresence(status: string) {
    console.log('&&&& setPresence &&&&');
    const db = getFirestore();
    if (this.storageService.uniTaskrUser) {
      const user = this.storageService.uniTaskrUser.userId
      if (user) {
        try {
          setDoc(doc(db, "status", `${user}`), {
            status: status,
            timestamp: new Date()
          }, { merge: true }).then(docSet => {
          }).catch(err => {
            console.log('Error setting doc: ', err);
          })

        } catch (e) {
          console.error("Error adding document: ", e);
        }
      } else {
        console.log('no user...')
      }
    }
  }

  newLastMessage(userId: number, message: string) {
    const db = getFirestore();
    try {
      setDoc(doc(db, "status", `${userId}`), {
        lastMessage: message,
        read: false,
        sender: this.storageService.uniTaskrUser.userId,
        timestamp: new Date()
      }, { merge: true }).then(docSet => {

      }).catch(err => {
        console.log('Error setting doc: ', err);
      })

    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  readLastMessage(userId: number) {
    const db = getFirestore();
    try {
      setDoc(doc(db, "status", `${userId}`), {
        read: true,
        timestamp: new Date()
      }, { merge: true }).then(docSet => {

      }).catch(err => {
        console.log('Error setting doc: ', err);
      })

    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  getPresence(userId: number) {
    const db = getFirestore();
    let docRef = doc(db, "status", `${userId}`);
    const unsub = onSnapshot(docRef, (doc) => {
      this.presence[userId] = doc.data();
    });
  }

  updateOnAway() {
    document.onvisibilitychange = (e) => {
      if (document.visibilityState === 'hidden') {
        this.setPresence('away');
      } else {
        this.setPresence('online');
      }
    };
  }
}
