import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController, LoadingController, Platform } from '@ionic/angular';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { ImageService } from 'src/app/services/image/image.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TaskService } from 'src/app/services/task/task.service';
import { Channel } from 'stream-chat';
import { ChannelService, ChannelListToggleService } from 'stream-chat-angular';
import { MessagingService } from '../../service/messaging.service';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss'],
})
export class CustomHeaderComponent implements OnInit {

  @Input() channel!: Channel;
  activeChannel: Channel | undefined;

  /** Focussed Task offer for channel */
  focussedTaskOffer;
  /** Focussed Student for channel */
  focussedStudent;
  /** Focussed Hirer for channel */
  focussedHirer;
  /** Focussed Task for channel */
  focussedTask;

  amHirer: boolean = false;

  paymentSummary;

  myCurrencyCode;
  myConversionRate;

  /** What device are we on? */
  isMobile: boolean = true;

  constructor(
    private router: Router,
    private platform: Platform,
    private actionSheetController: ActionSheetController,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private channelService: ChannelService,
    private messagingService: MessagingService,
    private taskService: TaskService,
    private profileService: ProfileService,
    public imageService: ImageService,
    private storageService: StorageService
  ) {
    this.channelService.activeChannel$.subscribe(chanRes => {
      console.log('----------- Header -----------');
      this.activeChannel = chanRes;
      console.log('active channel: ', this.activeChannel);
      if (this.activeChannel) {
        this.getHeaderUniTaskrFacts(this.activeChannel.id);
      }
      console.log('----------- Header -----------');
    });
  }

  ngOnInit() { 
    if (this.platform.is('mobile')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  toggleMenu() {
    this.messagingService.menuIsOpen = true;
    this.channelService.deselectActiveChannel();
  }


  getHeaderUniTaskrFacts(taskOfferId) {
    console.log('getHeaderUniTaskrFacts: ', taskOfferId);
    this.taskService.getTaskOfferById(taskOfferId).subscribe(taskOfferRes => {
      console.log('Task offer res: ', taskOfferRes);

      this.focussedTaskOffer = taskOfferRes;


    })

    
  }
 

  viewStudentProfile() {
    console.log('viewStudentProfile()');
    this.router.navigate([`/student-profile/${this.focussedTaskOffer.userId}`]);
  }



}
