import { Component, OnInit } from '@angular/core';
import { S3UploadService } from 'src/app/services/s3-upload/s3-upload.service';

@Component({
  selector: 'app-image-loading',
  templateUrl: './image-loading.component.html',
  styleUrls: ['./image-loading.component.scss'],
})
export class ImageLoadingComponent implements OnInit {

  constructor(
    public uploadService: S3UploadService
  ) { }

  ngOnInit() {}

}
