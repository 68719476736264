import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { HttpService } from 'src/app/services/http/http.service';
import { doc, getFirestore, setDoc, onSnapshot, arrayUnion } from '@firebase/firestore';

import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage/storage.service';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatBlazeService {

  constructor(
    private router: Router,
    private loadingController: LoadingController,
    private http: HttpService,
    private storageService: StorageService
  ) { }

  getChatHistory(channelId: string) {
    return this.http.runHttpCall(
      "POST",
      `api/channels/${channelId}/messages`,
      "application/json",
      {
        "take": 32767,
        "skip": 0
      }
    )
  }

  getConversationById(channelId: string) {
    return this.http.runHttpCall(
      "GET",
      `api/channels/${channelId}`,
      "application/json",
    )
  }

  addAdminMessageToChannel(channelId: string, messageRequest: MessageRequest) {
    console.log('addAdminMessageToChannel()')
    return this.http.runHttpCall(
      "POST",
      `api/channels/admin/${channelId}/messages/add`,
      "application/json",
      messageRequest
    ).pipe(tap(messageRes => {
      console.log('TAP TAP messageRes TAP TAP: ', messageRes);

      // Send a push notification

      // this.notificationService.sendUserNotification(messageRequest.message, this.getChattingWithID(), `${this.channelService.focussedConversation.name}`, `chat/${messageRequest.channelId}`).subscribe(async notificationRes => {
      //   console.log("notificationRes: ", notificationRes);
      // }, err => {
      //   console.log('err sending notification: ', err);
      // });
      // Send a push notification END
    }))
  }

  getMessageById(channelId: string, messageId: string) {
    return this.http.runHttpCall(
      "GET",
      `api/channels/${channelId}/messages/${messageId}`,
      "application/json").pipe(tap(async msgRes => {
        let stored = await this.storageService.setAndString(`api/channels/${channelId}/messages/${messageId}`, msgRes);
        // console.log('stored: ', stored);
      }))
  }

  getMessagesObs(channelId: string, messageId: string) {
    let msg = this.checkStorage(channelId, messageId)
    return msg;
  }

  checkStorage(channelId: string, messageId: string) {
    return this.storageService.getAndParseObservable(`api/channels/${channelId}/messages/${messageId}`).pipe(map(msgRes => {
      if (msgRes) {
        return msgRes
      } else {
        return false;
      }
    }))

  }

  getTaskOrServiceById(itemId: string) {
    return forkJoin({
      taskRequest: this.http.runHttpCall(
        "GET",
        `api/tasks/user/${itemId}`,
        "application/json"),
      serviceRequest: this.http.runHttpCall(
        "GET",
        `api/services/user/${itemId}`,
        "application/json")
    })
  }

  addAdminUserToChat(channelId: string) {
    return this.http.runHttpCall(
      "POST",
      `api/channels/admin/${channelId}/add/user`,
      "application/json")
  }

  getTaskServiceByOfferId(linkedEntityId: number, linkedEntityType: number) {
    return this.http.runHttpCall(
      "POST",
      `api/${linkedEntityType == 1 ? 'tasks' : 'services'}/admin/task-offer/${linkedEntityId}`,
      "application/json")
  }

  getPriceChangeById(changeId: string) {
    return this.http.runHttpCall(
      "GET",
      `api/pricechange/${changeId}`,
      "application/json"
    )
  }
}

export interface MessageRequest {
  id: string,
  isDeleted: boolean,
  createdDate: string,
  channelId: string,
  message: string,
  data: string,
  userId: number
}

export interface MessageResponse {
  channelId: string,
  createdDate: string,
  data: string,
  id: string,
  isDeleted: boolean,
  message: string,
  userId: number
}


