import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService {

  /** Which URL was I trying to access when I got bounced to login? */
  deniedURL: string;

  constructor(
    private router: Router,
    private platform: Platform,
    private storageService: StorageService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!this.storageService.firebaseUser) {
     
    } else {
      if (!this.storageService.firebaseUser && window.location.href.indexOf('landing-hire') == -1) {

      } else {
        return true;
      }
    }
  }
}
