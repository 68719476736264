import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { BlazeMessagingService } from 'src/app/messaging-boiler/service/blaze-messaging/blaze-messaging.service';
import { MessagingBoilerService } from 'src/app/messaging-boiler/service/messaging-boiler.service';
import { environment } from 'src/environments/environment';
import { Channel, StreamChat } from 'stream-chat';
import { ChannelService } from 'stream-chat-angular';

@Component({
  selector: 'app-applicant-item',
  templateUrl: './applicant-item.component.html',
  styleUrls: ['./applicant-item.component.scss'],
})
export class ApplicantItemComponent implements OnInit {

  @Input('person') person;

  @Output('talkingTo') talkingTo = new EventEmitter();

  itemChannel: Channel | null;

  /** checkChannelTimer  */
  checkChannelTimer;

  /** Channel Unread count */
  unReadCount: number = 0;


  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private channelService: ChannelService,
    private boilerService: MessagingBoilerService,
    public blazeService: BlazeMessagingService
  ) { }

  async ngOnInit() {
    if (this.person.status !== 1) {
      this.checkChannel();
    }
  }

  async checkChannel() {
    // console.log('checkChannel()' );
    const chatClient = StreamChat.getInstance<any>(environment.stream_io.apiKey);
    const filter = { type: 'messaging', id: `${this.person.taskOffer.taskOfferId}` };
    const channels = await chatClient.queryChannels(filter, {}, {
      watch: true, // this is the default
      state: true,
    }, {});
    this.itemChannel = channels[0];

    // this.itemChannel.on(event => {
    //   console.log(`Channel event - ${this.itemChannel.data.name}: `, event);
    // })

    // console.log('itemChannel: ', this.itemChannel);
    // console.log('itemChannel count: ', this.itemChannel.state.unreadCount);    
  }


  getUnreadCount() {
    let currentApplicantItemChan = this.blazeService.allMyChannels.filter((chan) => {
      if (chan.id == this.person.taskOffer.taskOfferId) {
        return chan;
      }
    });
    if (currentApplicantItemChan && currentApplicantItemChan.length > 0) {
      // return 1;
      return currentApplicantItemChan[0].state.unreadCount;
    }
    else {
      return 0
    }
  }

  async openStreamChatLocal(person) {
    this.talkingTo.emit(person);
  }

  getCurrentStatus(person) {
    switch (person.status) {
      case 1:
        return "Invited";
      case 2:
        return "Applied";
      case 3:
        return "Hired";
      case 4:
        return "Completed";
      case 5:
        return "Paid";
      case 6:
        return "Started";
      case 7:
        return "Declined";
    }
  }

  getStatusClass(person) {
    switch (person.status) {
      case 1:
        return "invited_class";
      case 2:
        return "applied_class";
      case 3:
        return "hired_class";
      case 4:
        return "completed_class";
      case 5:
        return "paid_class";
      case 6:
        return "started_class";
      case 7:
        return "declined_class";
    }
  }

}
