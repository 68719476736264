import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {

  /** Option input text */
  @Input('text') loadingText: string = "Loading...";

  /** What colour would you like it to be? */
  @Input('color') color: string;

  constructor() { }

  ngOnInit() {}

}
