import { Injectable, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, Observable, of, Subscription } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';
import { Channel, StreamChat } from 'stream-chat';
import { ChannelActionsContext, ChannelListToggleService, ChannelPreviewContext, ChannelService, ChatClientService, CustomTemplatesService, DefaultStreamChatGenerics, StreamI18nService } from 'stream-chat-angular';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  /** has the chat been initialised?? */
  isInit: boolean = false;

  /** have the channels been initialised? */
  isChannelInit: boolean = false;

  /** Should this be visible?? */
  showMessaging: boolean = false;
  chatIsReady$!: Observable<boolean>;

  @ViewChild('channelActionsTemplate')
  private channelActionsTemplate!: TemplateRef<ChannelActionsContext>;
  @ViewChild('channelPreview')
  private channelPreview!: TemplateRef<ChannelPreviewContext>;


  /** Is the side menu (list of channels) visible? */
  menuIsOpen: boolean = true;

  /** Stream Subscriptions */
  channelSubscription$: Subscription;

  /** All Channels */
  allChannels: Channel<DefaultStreamChatGenerics>[] = [];

  /** Active Channel */
  activeChannel: Channel | null;

  /** Behaviour subject so focussed chat knows to refresh the task offer */
  shouldGetTaskAgain: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private streamI18nService: StreamI18nService,
    private storageService: StorageService,
    private authService: AuthService,
    private channelListToggleService: ChannelListToggleService,
    private zone: NgZone
  ) { }

  async initialiseChat(initChat) {
    console.log('initialiseChat: ', initChat)
    console.log('uniTaskrUser: ', this.authService.uniTaskrUser)
    console.log('streamToken: ', this.authService.uniTaskrUser.streamToken)

    if (this.authService.uniTaskrUser && this.authService.uniTaskrUser.streamToken) {
      if (!this.isInit) {
        this.isInit = true;
        this.streamI18nService.setTranslation();

        console.log("&&&&&&&&&&&")
        console.log('Enter Messaging Page: ', this.chatIsReady$);
        console.log("&&&&&&&&&&&")

        this.chatIsReady$ = this.getStreamToken().pipe(
          switchMap((streamToken) => this.chatService.init(
            environment.stream_io.apiKey,
            // `admin_${this.storageService.firebaseUser.uid}`,
            `unitaskr_${this.storageService.firebaseUser.uid}`,
            this.authService.uniTaskrUser.streamToke
          )),
          map(() => {
            this.intialiseChannels(initChat)
            return true
          }),
          catchError((error) => {
            console.log('Catch error: ', error)
            return of(false)
          })
        )

      } else {
        if (initChat !== 0) {
          const chatClient = StreamChat.getInstance<any>('k9yeue4c2yx3');
          const filter = { type: 'messaging', id: initChat };
          console.log('query channels 2')
          const channels = await chatClient.queryChannels(filter);
          this.channelService.setAsActiveChannel(channels[0]);
        }
      }
    } else {
      console.log('Over here... ')
      this.router.navigate(['/thinking'])
    }


  }

  getStreamToken() {
    console.log('getStreamToken(): ', this.authService.uniTaskrUser.streamToken);
    return of(this.authService.uniTaskrUser.streamToken)
  }

  intialiseChannels(initChat) {
    console.log('*******')
    console.log('intialiseChannels(): ', initChat);
    console.log('*******')
    if (!this.isChannelInit) {

      console.log('sTARTING')

      this.isChannelInit = true;

      this.channelService.init({
        type: 'messaging',
        members: { $in: [`unitaskr_${this.storageService.firebaseUser.uid}`] },
      }, {}, {}, false).then(initRes => {
        console.log('initRes: ', initRes);
        this.channelSubscription$ = this.channelService.channels$.subscribe(
          async (c) => {
            console.log('Returned Channels: ', c);
            this.allChannels = c;

            if (this.activeChannel) {
              for (let chan of this.allChannels) {
                if (chan.cid == this.activeChannel.cid) {
                  this.shouldGetTaskAgain.next(true);
                }
              }
            }

            if (initChat !== 0) {
              const chatClient = StreamChat.getInstance<any>('k9yeue4c2yx3');
              const filter = { type: 'messaging', id: initChat };
              console.log('query channels 1')
              const channels = await chatClient.queryChannels(filter);

              // this.channelService.setAsActiveChannel(channels[0]);
            } else {
              this.zone.run(() => {
                this.channelListToggleService.open();
              })
            }
          }
        );
        this.channelService.activeChannel$.subscribe(
          (c) => {
            this.zone.run(() => {
              this.activeChannel = c;
            })
          }
        );
      })
    } else {
      console.log('Peekaboo!');
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }


  /**
   * 
   * Create a new channel with me in it.
   * UniBot will be in the channel too.
   * 
   * @param name - name of the channel
   */
  createChannelForMe(name) {
    console.log('createChannelForMe(): ', name);
    const dasherizedName = name.replace(/\s+/g, '-').toLowerCase();
    const channel = this.chatService.chatClient.channel(
      'messaging',
      dasherizedName,
      {
        name,
        members: [this.storageService.firebaseUser.uid, 'uniBot']
      });
    from(channel.create());
  }

  /**
   * 
   * Create a new channel for a specific task.
   * UniBot will be in the channel.
   * I will be inthe channel.
   * 
   * I am the hirer, and am starting a chat with a student.
   * 
   * @param name - name of the channel
   * @param offerId - task offer ID that we're talking about.
   */
  createChannelForOfferId(name: string, offerId: string, studentUid: string) {
    console.log('createChannelForMe(): ', name);
    const dasherizedName = name.replace(/\s+/g, '-').toLowerCase();
    const channel = this.chatService.chatClient.channel(
      'messaging',
      offerId,
      {
        name,
        members: [this.storageService.firebaseUser.uid, studentUid, 'uniBot']
      });
    from(channel.create());
  }
}
